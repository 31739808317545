body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f3f4f6;
  color: #333;
  min-height: 100vh;
  overflow-x: hidden;
}

.navbar-custom {
  background-color: #1f2937;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: #fff;
  transition: color 0.3s ease;
}

.navbar-custom .nav-link:hover {
  color: #d1d5db;
}

.navbar-custom .navbar-toggler {
  border: none;
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.hero-section {
  background: linear-gradient(135deg, #4f46e5, #3b82f6);
  color: #fff;
  padding: 100px 0;
  display: flex;
  align-items: center;
  padding-top: 80px;
  position: relative;
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content {
  max-width: 600px;
}

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mission-section {
  background-color: #f9fafb;
  padding: 50px 0;
  width: 100%;
}

.values-section {
  background-color: #f3f4f6;
  padding: 50px 0;
  text-align: center;
}

.value-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333;
}

.value-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.value-icon {
  margin-bottom: 20px;
  color: #4f46e5;
}

.about-us-section {
  background-color: #f9fafb;
  padding: 50px 0;
}

.about-us-box {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.contact-section {
  background-color: #f3f4f6;
  padding: 50px 0;
  text-align: center;
}

.contact-box {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.footer {
  background-color: #1f2937;
  color: #fff;
  padding: 40px 0;
}

.footer h5 {
  color: #fff;
  margin-bottom: 20px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer p {
  margin: 0;
}

.nav-link {
  position: relative;
}

.nav-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.nav-link:hover::before {
  visibility: visible;
  transform: scaleX(1);
}
